@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}
html {
  height: 100%
}

#root {
  height: 100%;
  --color-solid-resize-bar: lightgray;
  --color-solid-resize-bar-handle: #7194a8;
}

[data-overlay-container=true] {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tiptap {
  height: 100%;
  outline: none;
  color: black
}

.resize-icon {
  width: 0.8em;
  height: 1.8em;
  position: absolute;
  left: calc(50% - 0.4rem);
  top: calc(50% - 0.9rem);
  background-color: rgb(202, 203, 202);
  padding: 0.3rem 0rem;
  border-radius: 0.25rem;
  color: #898888;
}
.resize-handler-border[data-resize-handle-active] .resize-icon {
  background-color: var(--color-solid-resize-bar-handle);
  color: #e5d9a8
}

.resize-handler-border {
  flex: 0 0 0.2em;
  position: relative;
  outline: none;
  background-color: var(--color-solid-resize-bar);
}
.resize-handler-border[data-resize-handle-active] {
  background-color: var(--color-solid-resize-bar-handle);
}

#document-editor-container div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#note-editor-container div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
