
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tiptap {
  font-family: 'Lora', serif;
}

.tiptap p {
  margin-bottom: 16px; /* Adjust this value to control the spacing */
}

.tiptap h1{
  font-size: 2em;
  font-weight: bold;
}
.tiptap h2{
  font-size: 1.5em;
  font-weight: bold;
}
.tiptap h3{
  font-size: 1.17em;
  font-weight: bold;
}
.tiptap h4{
  font-weight: bold;
}
.tiptap ul {
  list-style: disc;
  padding-left: 1.5em;
}
.tiptap ol {
  list-style: decimal;
  padding-left: 1.5em;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.blurred-div {
  background-color: rgba(126, 126, 126, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.blurred-div-light {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.draft-title-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}